<template>
  <section>
    <div class="row login-form" style="padding: 0px 40px ;">
      <!--   NEW MEMBER  -->

      <div id="form-regis" class="col-md-12 col-regis">
        <h2 style="margin-top: 5px;">สมัครสมาชิก</h2>
        <div class="row">
          <div class="col-xs-12" style="margin: 0px;
            background-color: white;
            padding:20px 20px;
            border-radius: 7px;
            height: auto;
            ">
            <div class="row">

              <div class="form-group col-md-6">
                <label for="mbltxtR"> เบอร์ติดต่อ</label><span style="color: red;">*</span>
                <input v-model="dataRegister.MOBILE" @blur="getTheOneNumber" ref="MOBILE" type="text" class="register-input" name="MOBILE" maxlength="10" autocomplete="off">
              </div>
              <div class="form-group col-md-6">
                <label for="nmetxtR"> ชื่อ-นามสกุล</label><span style="color: red;">*</span>
                <input v-model="dataRegister.CNAME" ref="CNAME" type="text" class="register-input" name="CNAME"  maxlength="150" autocomplete="off">
              </div>
              <div class="form-group col-md-12">
                <label for="emltxtR">อีเมล</label><span style="color: red;">*</span>
                <input v-model="dataRegister.EMAIL" ref="EMAIL" type="email" class="register-input" name="EMAIL"  maxlength="100" autocomplete="off">
              </div>
      
              <div class="form-group col-md-6">
                <label for="usrtxtR">USERID</label><span style="color: red;">*</span>
                <input v-model="dataRegister.VIPID" ref="VIPID" type="text" class="register-input" name="VIPID"  maxlength="10" autocomplete="off">
              </div>
              <div class="form-group col-md-6">
                <label for="theonetxtR"> หมายเลขสมาชิก The 1 </label><span style="color: red;" v-if="NT1C"> (ไม่พบเลขThe1)</span>
                <input v-model="dataRegister.T1C" ref="T1C"  type="textR" class="register-input" name="T1C" 
                  placeholder="ห้ามใส่เบอร์ลูกค้า"  maxlength="16" disabled>
                <!-- <a :class="`font-color-${qtBu}`" style="font-size: 1em; text-decoration: underline;" @click="getTheOneNumber">ค้นหาเลขเดอะวัน</a> -->
              </div>
              <!-- <button type="button" class="" style="  background-color:black; width:100%; 
                    border: none;
                    color: white;
                    padding: 2px 12px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    
                    margin: 0px;
                 
                    cursor: pointer;
                    border-radius: 4px; " @click="randomUser"> <strong
                  style="font-size: 20px !important; font-family: 'kittithadamedium65regular';"> Random Userid &amp;
                  Email</strong></button> -->
              <button type="button" :class="`mt-1 twdorbnb btn-${qtBu}`"  @click="createUser"> <strong
                  style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ส่งฟอร์มการสมัคร</strong></button>
  
            </div>
          </div>


          <div class="modal-footer">
            <p><br></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  import * as serviceAPI from '@/services/API.service';
  import * as serviceMain from '@/services/main.service';

  export default {
    name: 'RegisterformAuto1',
    data: function () {
      return {
        // dataRegister: {
        //   MOBILE: "",
        //   EMAIL:"",
        //   CNAME:"",
        //   VIPID:"",
        //   T1C:"",
        //   SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
        // },
        NT1C:false
      }
    },
    props: [
      "dataRegister"
    ],
    mounted() {

    },
    methods: {

      // async startUser() {
      //   serviceMain.saveCookieEncrypt("TEST","VALTEST")
      //   let getCookie =  serviceMain.readCookieEncrypt("TEST")
      //   alert(getCookie)
      // },
      async getTheOneNumber(){
        try {
          if(this.dataRegister.MOBILE.length != 10){
            return
          }
          let data = this.dataRegister;
          let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
          let getData = getAPI.data.dbitems[0];
          
          if(!getData ||getData.T1CNumber=='NOOK'){
            this.dataRegister.T1C = ''
            this.dataRegister.CNAME = ''
            this.NT1C = true
            return
            // throw new Error('ไม่พบเลขเดอะวัน')
          }
          this.dataRegister.T1C = getData.T1CNumber;
          this.dataRegister.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
          this.NT1C = false
        } catch (error) {
          this.dataRegister.T1C = ''
          this.dataRegister.CNAME = ''
          this.NT1C = true
          this.$emit("setisloading",false);
          // serviceMain.showErrorAlert(this, error.message)
        }
      },
      async randomUser() {
        try {
            let getmbl= this.dataRegister.MOBILE.trim();
            let phoneno = /^\d{10}$/;

            if(getmbl.length == 0) {
              this.$refs.MOBILE.focus() 
              throw new Error("กรุณาใส่หมายเลขโทรศัพท์")
            }

            if(!getmbl.match(phoneno)){
              this.$refs.MOBILE.focus() 
              throw new Error('หมายเลขโทรศัพท์ต้องเป็นตัวเลข 10 หลัก');
            } 

            let data = this.dataRegister;
            let getAPI = await serviceAPI.call_API('post', 'user/randomuser', data,'auth');
            let getData = getAPI.data.dbitems[0];
            this.dataRegister.MOBILE = getData.MOBILE;
            if(this.dataRegister.EMAIL==''){
              this.dataRegister.EMAIL = getData.EMAIL;
            }
            if(this.dataRegister.CNAME==''){
              this.dataRegister.CNAME = getData.CNAME;
            }
            if(this.dataRegister.VIPID==''){
              this.dataRegister.VIPID = getData.VIPID;
            }
            if(this.dataRegister.T1C==''){
              this.dataRegister.T1C = getData.T1C;
            }

        } catch (error) {
          serviceMain.showErrorAlert(this, error.message)
        }
      },
      async createUser() {
        try {
            this.$emit("setisloading",true);
            // let getsalesource = this.$store.getters['auth/getUser'].QTSALESOURCE;
            let usr = this.dataRegister.VIPID;
            let eml = this.dataRegister.EMAIL;
            let nme = this.dataRegister.CNAME;
            let tel = this.dataRegister.MOBILE;
            let theone = this.dataRegister.T1C;
            usr=usr.trim();
            let usrno = /^[0-9a-zA-Z]+$/; 
            if(usr.length == 0) {
              this.$refs.VIPID.focus() 
              throw new Error('กรุณาใส่ USERID');
            }  
            if(!usr.match(usrno)) {
              this.$refs.VIPID.focus() 
              throw new Error('USERID ต้องเป็นภาษาอังกฤษหรือตัวเลข');
            }
            nme=nme.trim();
            if(nme.length == 0){
              this.$refs.CNAME.focus() 
              throw new Error('กรุณาใส่ชื่อ');
            }
            let emlno = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; 
            eml=eml.trim();
            if(eml.length == 0) {
              this.$refs.EMAIL.focus() 
              throw new Error('กรุณาใส่อีเมล');
            }
            if(eml.indexOf('@')<0){
              this.$refs.EMAIL.focus() 
              throw new Error('อีเมลไม่ถูกต้อง');
            } 
            if(!eml.match(emlno)) {
              this.$refs.EMAIL.focus() 
              throw new Error('อีเมลต้องเป็นภาษาอังกฤษหรือตัวเลข');
            } 
            tel=tel.trim();
            let phoneno = /^\d{10}$/;
            if(tel.length == 0) {
              this.$refs.MOBILE.focus()
              throw new Error('กรุณาใส่หมายเลขโทรศัพท์');
            }
            if(!tel.match(phoneno)) {
              this.$refs.MOBILE.focus()
              throw new Error('หมายเลขโทรศัพท์ต้องเป็นตัวเลข 10 หลัก');
            } 
            theone=theone.trim();    
            let theoneno = /^\d{16}$/;
            let theonenoold = /^\d{10}$/;
            if((!theone.match(theoneno) && !theone.match(theonenoold)) && theone.length != 0) {
            this.$refs.T1C.focus()
              throw new Error('หมายเลขเดอะวันการ์ดต้องเป็นตัวเลข 10 หรือ 16 หลัก');      
            } 
            let data = this.dataRegister;
            await serviceAPI.call_API('post', 'user/Custinfo', data,'auth');
            serviceMain.showSuccessAlertWithTime(this, "สร้าง User สำเร็จ",1500)
            this.$emit('confirmCreateUser')
   
        } catch (error) {
          this.$emit("setisloading",false);
          serviceMain.showErrorAlert(this, error.message)
        }
      },
 



    },
    computed: {
        qtBu: function () {
            return this.$store.getters['customer/getQTBU'];
        }
    }
  }
</script>

<style scoped>
  .register-input {
    width: 100% !important;
    border-radius: 4px;
    border: .5px solid #ccc;
    display: inline-block !important; 
  }
</style>

