<template>
  <section>
    <div v-show="!isloading" class="row login-form" style="padding: 0px 40px ; ">
        <div id="form-regis" class="col-md-12 col-regis">
            <h2 style="margin-top: 5px;">ค้นหาJob WholeSale </h2>
            <div class="row mb-3">
                <div class="col-12" style="margin: 0px; background-color: white; padding:20px 20px; border-radius: 7px; height: auto;  min-height: auto;">
                    <div class="row">
                        <div class="form-group col-12" style="padding: 0px;">
                            <div class="col-12">
                                <label for="JOBNO">กรุณากรอกเลขที่Job</label> <span style="color: red;">*</span>
                            </div>
                            <div class="col-12">
                                <input v-model="jobNo" type="text" class="" style="width: 100% !important; border-radius: 4px; border: .5px solid #ccc; display: inline-block !important; " 
                                ref="JOBNO" name="JOBNO" :placeholder="`${qtDfStore ? '' : 'กรุณาเลือกสาขา'}`">
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <button type="button" :class="`twdorbnb btn-${getQTBU}`" style=" " @click="getJobWholeSale()" > <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ค้นหาข้อมูลJob</strong></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-show="isloading" class="loading"></div>
  </section>
</template>

<script>
// @ is an alias to /src
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
// import OrderFromAuto1 from '@/components/OrderAuto1/OrderFromAuto1.vue'

export default {
  name: 'OrderWholeSaleMain',
  components: {
    // OrderFromAuto1,
  },
  data: function () {
    return {
      jobNo:'',
      listItems:[],
      listUser:'',
      dataEmail:'',
      dataPhone:'',
      salesourceCondition: ['UL', 'UD', 'UC'],
      dataSelected: {
          CNAME: '',
          MOBILE: '',
          EMAIL: '',
          T1C: '',
          VIPID: '',
          SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
          EMPLOYEEBUSHORTCODE: '',
          EMPLOYEEID: '',
          ISSTAFF: '',
      },
      dataRegister: {
          MOBILE: "",
          EMAIL:"",
          CNAME:"",
          VIPID:"",
          T1C:"",
          SALESOURCE: "",
      },
      address: {
          adrname: '',
          name: '',
          lname: '',
          iscom: 1,
          company: '',
          namenocom: '',
          lnamenocom: '',
          idnum: '',
          idbranch: '',
          phone: '',
          phone2: '',
          adrnum: '',
          build: '',
          floor: '',
          room: '',
          moo: '',
          soi: '',
          road: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
          ulat: '',
          ulong: '',
          typeofadr: '',
          othertxt: '',
          havele: '',
          can: '',
          adrstt: '',
          ishq: 'Y',
          isDeliveryAddress: false,
          isTaxAddress: false,
          isCompany: false,
          isShowZip: false
      },
      billaddress: {
          adrname: '',
          name: '',
          lname: '',
          iscom: 1,
          company: '',
          namenocom: '',
          lnamenocom: '',
          idnum: '',
          idbranch: '',
          phone: '',
          phone2: '',
          adrnum: '',
          build: '',
          floor: '',
          room: '',
          moo: '',
          soi: '',
          road: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
          ulat: '',
          ulong: '',
          typeofadr: '',
          othertxt: '',
          havele: '',
          can: '',
          adrstt: '',
          ishq: 'Y',
          isDeliveryAddress: false,
          isTaxAddress: false,
          isCompany: false,
          isShowZip: false
      },
      listData:{},
      showRegister:false,
      isloading:false,
      onEdit: 'N',
      onEditbill: 'N',
      listDataAddress:[],
      saveCurrentZipData:[],
      saveCurrentZipDatabill:[],
      zipData:{
                tbn: [],
                dst: [],
                pvn: []
            },
      zipDataBill:{
                tbn: [],
                dst: [],
                pvn: []
            }
    }
  },
  mounted() {

  },
  methods: {
    async getJobWholeSale(){
      try {
        this.isloading=true
        // if(!this.qtDfStore){
        //   this.isloading=false
        //   throw new Error(`กรุณาเลือกสาขาก่อน`)
        // }
        let data = {JobNo:this.jobNo}
        let getAPI1 = await serviceAPI.call_API('post', 'at1Order/checkConfirmOrder', {OrderNumber:this.jobNo},'auth');
        let getData1 = getAPI1.data.dbitems
        if(getData1.length != 0){
            this.isloading=false
            throw new Error(`เลขที่ใบงาน ${this.jobNo} สร้างใบเสนอราคาแล้ว`)
        }
        let getAPI = await serviceAPI.call_API('post', 'wholesale/getJobWholeSale', data,'auth');
        let getData = getAPI.data.data.data
        // if(!getData.the1CardNo){
        //     throw new Error('Job Order ไม่มีเลข The1');
        // }
        if(!getData.firstName){
            throw new Error('Job Order ไม่มีชื่อลูกค้า');
        }
        if(!getData.mobileNo){
            throw new Error('Job Order ไม่มีเลขเบอร์โทรศัพท์');
        }
        // if(!getData.email){ // ไม่มีเมลส่งมาให้เช็คว่ามาอยู่แล้วไหมหรือใส่เป็นdummy
        //     throw new Error('Job Order ไม่มีเลขเบอร์โทรศัพท์');
        // }
        if(!getData.customerId){
            throw new Error('Job Order ไม่มีรหัสลูกค้า');
        }
        if(!getData.memberLevelID){
            throw new Error('Job Order ไม่มี Member Level ID');
        }

        if(getData.directSaleCustomerId){
            console.log(this.qtDfStore);
            if(this.qtDfStore == '' || this.qtDfStore == null){
                throw new Error('กรุณาเลือกสาขาก่อนครับ');
            }
            this.isloading=false
            let dataCKDSC={
            REF:this.jobNo,
            // STCODE:this.$store.getters["service/getStcShowStock"],
            STCODE:this.qtDfStore,
            CustomerDSID:getData.directSaleCustomerId
            }
            // console.log(data);
            let getAPICKDSC = await serviceAPI.call_API('post',`wholesale/checkCreditDS`,dataCKDSC,'auth');
            let getDataCKDSC = JSON.parse(getAPICKDSC.data.data);
            // console.log(getData);
            if(getDataCKDSC.status == 0){
            throw new Error(getDataCKDSC.message)
            }
            let creditRemain = getDataCKDSC.data.creditremain
            // console.log('เครดิต'+this.creditRemain+'ขาย'+getSumTOT);
            // if(creditRemain <= 0){
            //   throw new Error(`เครดิตลูกค้า ${creditRemain}`)
            this.$swal.fire({
                html: `จำนวนเครดิตลูกค้า ${creditRemain}`,
                showCancelButton: true,
                confirmButtonColor: this.getBuColor,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'เปิดใบเสนอราคา',
                denyButtonText: "ยกเลิก",
            }).then(async (result) => {
                if (result.isDenied) {
                    // console.log('CheckOut');
                    // this.$router.push('/CheckOut');
                    return
                }
                if(result.isConfirmed){
                    this.getJobWholeSaleCreate(getData)
                }
            }).catch((error) => {
                serviceMain.showErrorAlert(this, error)
            });
            // }
        }else{
            this.getJobWholeSaleCreate(getData)
        }
      } catch (error) {
          this.isloading=false
          serviceMain.showErrorAlert(this,error)
      }
    },

    async getJobWholeSaleCreate(getData){
      try {
        // if(!getData.memberID){
        //     throw new Error('Job Order ไม่มีรหัสลูกค้า');
        // }
        // console.log(getData);
        if(getData.directSaleMMSStoreCode){
          let dataStCode = {store:getData.directSaleMMSStoreCode}
        //   console.log(dataStCode);
          let getAPI2 = await serviceAPI.call_API('post', 'at1Order/getStCode', dataStCode,'auth');
          let getData2 = getAPI2.data.dbitems
        //   console.log(getData2);
          if(getData2.length == 0){
              this.isloading=false
              throw new Error(`ไม่พบเลขที่สาขา ${getData.StoreCode}`)
          }
          if(!getData2[0].STCODE || getData2[0].STCODE != this.qtDfStore){
              // this.isloading=false
              // this.$store.dispatch('service/setQTDFSTORE',getData2[0].STCODE)
              // throw new Error(`สาขาไม่ตรงกับJob`)
              // alert(`สาขาไม่ตรงกับOrder`)
          }
          this.$store.dispatch('service/setDSCUSTID', getData.directSaleCustomerId)
          this.$store.dispatch('service/setDSMMSSTORE', getData2[0].STCODE)
        }
        this.listData = getData
        this.$store.dispatch('service/setWSOTP', getData.otp)
        this.$store.dispatch('service/setWSdiscountBill', getData.discountBill)
        this.$store.dispatch('service/setWSplpp', getData.plpp)
        this.$store.dispatch('service/setWSt1c', getData.t1c)
        // if(getData.Message != 'OK'){
        //     throw new Error(`ไม่พบเลขที่ใบงาน ${this.jobNo}`)
        // }
        this.dataRegister = {
            MOBILE: getData.Mobile,
            EMAIL: getData.Email || `mrWS${getData.custid}@dummy.com`,
            CNAME:`${getData.Fname} ${getData.Lname}`,
            VIPID:`WS${getData.custid}`,
            T1C:getData.T1C || "",
            SALESOURCE: "WS",
        }
        await this.confirmSelectedUser()
        // if(this.getDSMMSSTORE && this.getDSMMSSTORE != this.qtDfStore){
        //     // $(".loading").hide();
        //     // this.isLoading = false
        //     // throw new Error(`สาขาไม่ตรงกับJob`)
        //     this.$store.dispatch('service/setQTDFSTORE',this.getDSMMSSTORE)
        //     $('#mapModal').modal('hide')
        //   }
      } catch (error) {
          this.isloading=false
          serviceMain.showErrorAlert(this,error)
      }
    },
    async getTheOneNumber(){
        try {
            let data = this.dataSelected;
            let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
            let getData = getAPI.data.dbitems[0];
            this.loadT1C = true
            if(!getData ||getData.T1CNumber=='NOOK'){
                // throw new Error('ไม่พบเลขเดอะวัน')
                // this.dataSelected.T1C = getData.T1CNumber || '' ;
                this.dataSelected.EMPLOYEEBUSHORTCODE = '';
                this.dataSelected.EMPLOYEEID = '';
                this.dataSelected.ISSTAFF = '';
                // await this.confirmSelectedUser()
                return
            }
            // this.dataSelected.T1C = getData.T1CNumber || '' ;
            // this.dataSelected.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
            this.dataSelected.EMPLOYEEBUSHORTCODE = getData.EmployeeBUShortCode || '';
            this.dataSelected.EMPLOYEEID = getData.EmployeeId || '';
            this.dataSelected.ISSTAFF = getData.EmployeeFlag || '';
            // await this.confirmSelectedUser()
        } catch (error) {
            this.loadT1C = true
            this.dataSelected.EMPLOYEEBUSHORTCODE = '';
            this.dataSelected.EMPLOYEEID = '';
            this.dataSelected.ISSTAFF = '';
            // await this.confirmSelectedUser()
            // serviceMain.showErrorAlert(this, error.message)
        }
    },
    async confirmSelectedUser() {
        try {
            let data = {
                "MCID": this.$store.getters['customer/getQTMCID'],
                "USERID": this.$store.getters['auth/getQTUSERID'],
                "JOBORDER": this.jobNo,
                "JSNJOBORDER": this.listData
            }
            let getAPI = await serviceAPI.call_API('post', 'wholesale/getCustrefWholesale', data, 'auth');
            
            let getData = getAPI.data.dbitems[0];
            // console.log(getData);

            this.dataSelected.CNAME = this.listData.firstName+' '+this.listData.lastName ;
            this.dataSelected.MOBILE = this.listData.mobileNo;
            this.dataSelected.EMAIL = getData.EMAIL;
            this.dataSelected.T1C = this.listData.the1CardNo;
            this.dataSelected.VIPID = getData.VIPID;
            await this.getTheOneNumber()
            
            let getCUSTREF = getData.CUSTREF;
            let getNITEM = getData.SUMQNT;
            let getCURSTC = getData.CURSTC ? getData.CURSTC.trim() : '';
      
            let getAPI3 = await serviceAPI.call_API('post', 'wholesale/getDetail4ReserveCredit', { REF:getCUSTREF },'auth');
            if(getAPI3.data.dbitems[0].PONUM){
                this.$store.dispatch('customer/setQTPONUM', getAPI3.data.dbitems[0].PONUM)
            }

            this.$store.dispatch('customer/setQTCUSTINFO', this.dataSelected)
            this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
            this.$store.dispatch('customer/setQTCOPYREF', this.jobNo)
            this.$store.dispatch('customer/setQTCREDITBAL', this.listData.creditBalance || '')
            this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
            serviceMain.showSuccessAlertWithTime(this,`เลือกตะกร้า ${getCUSTREF} สำเร็จ`,1000)
            // {
            //   "shippingAddressId": "B4362616-9000-11ED-98BD-005056BF5E2D",
            //   "customerFullName": "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) สำนักงานใหญ่",
            //   "ishq": null,
            //   "homeNo": "313",
            //   "buildingName": "ซี.พี.ทาวเวอร์",
            //   "floorNo": "",
            //   "villageName": "",
            //   "roomNo": "",
            //   "mooNo": "",
            //   "alleyName": "",
            //   "roadName": "สีลม",
            //   "subDistrictName": "สีลม",
            //   "provinceName": "กรุงเทพมหานคร",
            //   "postalCode": "10500",
            //   "taxNo": "0107537000246"
            // } 
            this.address= {
                adrname: this.listData.address.shippingAddressId,
                name: this.listData.address.contactName ? this.listData.address.contactName : this.listData.firstName,
                lname: this.listData.address.contactName ? '' : this.listData.lastName,
                iscom: 0,
                company: '',
                namenocom: '',
                lnamenocom: '',
                idnum: '',
                idbranch: '',
                phone: this.listData.address.contactMobileNo1 || this.listData.mobileNo,
                phone2: '',
                adrnum: this.listData.address.homeNo,
                build: this.listData.address.villageName || '' + this.listData.address.buildingName || '',
                floor: this.listData.address.floorNo,
                room: '',
                moo: this.listData.address.mooNo,
                soi: this.listData.address.alleyName,
                road: this.listData.address.roadName,
                subdistrict: this.listData.address.subDistrictName,
                district: this.listData.address.districtName,
                province: this.listData.address.provinceName,
                zipcode: this.listData.address.postalCode,
                ulat: '',
                ulong: '',
                typeofadr: '',
                othertxt: '',
                havele: '',
                can: '',
                adrstt: '',
                ishq: 'N',
                isDeliveryAddress: false,
                isTaxAddress: false,
                isCompany: false,
                isShowZip: false
            }
            // {
            //   "billAddrId": 8,
            //   "fulltaxCustomerId": "A42621A2-4B8F-4CDB-A3A4-8901899ADAF4",
            //   "companyName": "บริษัท เจริญโภคภัณฑ์อาหาร จำกัด (มหาชน) สำนักงานใหญ่",
            //   "firstName": null,
            //   "lastName": null,
            //   "ishq": null,
            //   "mobileNo": null,
            //   "email": null,
            //   "homeNo": "313",
            //   "alleyName": "",
            //   "buildingName": null,
            //   "villageName": null,
            //   "floorNo": null,
            //   "roomNo": "",
            //   "mooNo": "",
            //   "roadName": "สีลม",
            //   "subDistrictName": "สีลม",
            //   "districtName": "บางรัก",
            //   "provinceName": "กรุงเทพมหานคร",
            //   "postalCode": "10500",
            //   "idnum": "0107537000246",
            //   "iscom": 1
            // }
            this.billaddress= {
                adrname: this.listData.billAddr.fulltaxCustomerId,
                name: this.listData.firstName,
                lname: this.listData.lastName,
                iscom: this.listData.billAddr.iscom,
                company: this.listData.billAddr.iscom == 1 ? this.listData.billAddr.companyName : '',
                namenocom: this.listData.billAddr.iscom == 1 ? '' : this.listData.firstName,
                lnamenocom: this.listData.billAddr.iscom == 1 ? '' : this.listData.lastName,
                idnum: this.listData.billAddr.idnum,
                idbranch: this.listData.billAddr.ishq == '1'? '' : this.listData.billAddr.idBranch,
                phone: this.listData.billAddr.mobileNo,
                phone2: '',
                adrnum: this.listData.billAddr.homeNo,
                build: this.listData.billAddr.villageName || '' + this.listData.billAddr.buildingName || '',
                floor: this.listData.billAddr.floorNo,
                room: this.listData.billAddr.roomNo,
                moo: this.listData.billAddr.mooNo,
                soi: this.listData.billAddr.alleyName,
                road: this.listData.billAddr.roadName,
                subdistrict: this.listData.billAddr.subDistrictName,
                district: this.listData.billAddr.districtName,
                province: this.listData.billAddr.provinceName,
                zipcode: this.listData.billAddr.postalCode,
                ulat: '',
                ulong: '',
                typeofadr: '',
                othertxt: '',
                havele: '',
                can: '',
                adrstt: '',
                ishq: this.listData.billAddr.ishq == '1' ? 'Y' : 'N',
                isDeliveryAddress: false,
                isTaxAddress: false,
                isCompany: false,
                isShowZip: false
            }
            this.$store.dispatch('service/setDSBillAddress', this.listData.billAddr.fulltaxCustomerId)
            await this.getAddress()
            // console.log(this.address);
            // console.log(this.dataSelected);
            if(this.listData.address.postalCode){
                if(this.address.adrstt != 'all'){
                await this.changZipcode(this.address.zipcode)
                await this.saveAddress()
                }
            }
            if(this.listData.billAddr.postalCode){
                await this.changZipcodeBill(this.billaddress.zipcode)
                await this.saveAddressBill()
            }
            if (getCURSTC) {
                this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                    "STCODE": getCURSTC
                }, 'auth');
                let getBU = getAPI.data.dbitems[0].BU;
                this.$store.dispatch('customer/setQTBU', getBU)
                let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                let locationsTEMP = getQTSAVESTORE.split('|');
                let locations = locationsTEMP.map(e => e.split(','));
                let getQTStoreNow = getCURSTC
                let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                let getStoreName = QTStoreName[0][1];
                this.$store.dispatch('service/setQTSTORENAME', getStoreName)
            } else {
                if(!this.qtDfStore || !this.getDSMMSSTORE) {
                    $('#mapModal').modal('show')
                }
            }
            
            let getSaleSource = getData.SALESOURCE;
            if(this.salesourceCondition.includes(getSaleSource)){
                this.$store.dispatch('auth/setQTSUBSALESOURCE', this.getQTSUBSALESOURCE)
            }else{
                this.$store.dispatch('auth/setQTSUBSALESOURCE', getSaleSource)
            }
                
            this.updateSaleSource()
        } catch (error) {
            this.isloading=false
            serviceMain.showErrorAlert(this, error.message)
        }

    },
    async updateSaleSource(){
        try {

            if(!this.getRef){
            throw new Error("กรุณาเลือกตะกร้าก่อนครับ")
            }
            let data={
            REF: this.getRef, 
            SALESOURCE:this.getQTSUBSALESOURCE
            }
            let getAPI = await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
            this.isloading=false
            this.$router.push('/');
            
        } catch (error) {
            this.isloading=false
            serviceMain.showErrorAlert(this,error.message)
        }
    },
    async saveAddress() {
      try {
          await serviceMain.Delay(1000);
          let getDataLatLng = this.saveCurrentZipData.find(e => e.SUBDISTRICT.trim() == this.address.subdistrict && e.DISTRICT.trim() == this.address.district && e.PROVINCE.trim() == this.address.province)
          if(!getDataLatLng){
            throw new Error("ไม่สามารถเพิ่มที่อยู่จัดส่งได้ (ที่อยู่ไม่ถูกต้อง)")
          }
          this.address.ulat = getDataLatLng.LAT
          this.address.ulong = getDataLatLng.LNG
          let data = {
              MODE: this.onEdit,
              VIPID: this.dataSelected.VIPID,
              ADDRESS_JSON: this.address,
              REF: ''
          }
        //   console.log(data);
          let getAPI = await serviceAPI.call_API('post', `checkout/SaveMultipleDlv`, data, 'auth');
          serviceMain.showSuccessAlertWithTime(this, `${this.onEdit ? 'แก้ไข' : 'เพิ่ม'}ที่อยู่ ${this.address.adrname} แล้ว`, 1200)
      } catch (error) {
          serviceMain.showErrorAlert(this, error)
      }

    },
    async changZipcode(para_zip) {
        try {
            if (para_zip.length == 5) {

                let data = {
                    ZIPCODE: para_zip
                }
                let getAPI = await serviceAPI.call_API('post', `checkout/getAddrbyZipcode`, data, 'auth');
                if (getAPI.data.dbrows == 0) {
                    throw new Error("ไม่พบรหัสไปรษณีย์ครับ")
                }
                let getData = getAPI.data.dbitems;
                this.saveCurrentZipData = getData;
                let getTAMBON = [];
                let getDISTRICT = [];
                let getPROVINCE = [];
                let oldTAMBON = null;
                let oldDISTRICT = null;
                let oldPROVINCE = null;
                for (let e of getData) {
                    if (e.TAMBON != oldTAMBON) {
                        getTAMBON.push(e.TAMBON.trim())
                        oldTAMBON = e.TAMBON.trim();
                    }
                    if (e.DISTRICT != oldDISTRICT) {
                        getDISTRICT.push(e.DISTRICT.trim())
                        oldDISTRICT = e.DISTRICT.trim();
                    }
                    if (e.PROVINCE != oldPROVINCE) {
                        getPROVINCE.push(e.PROVINCE.trim())
                        oldPROVINCE = e.PROVINCE.trim();
                    }
                }

                this.zipData = {
                    tbn: getTAMBON,
                    dst: getDISTRICT,
                    pvn: getPROVINCE
                }

                this.address.subdistrict = this.address.subdistrict || this.zipData.tbn[0]
                this.address.district = this.address.district || this.zipData.dst[0]
                this.address.province = this.address.province || this.zipData.pvn[0]
                this.address.isShowZip = true;
                // console.log(this.zipData);
            } else {
                this.address.isShowZip = false;
                this.zipData = {
                    tbn: [],
                    dst: [],
                    pvn: []
                }
            }
        } catch (error) {
            serviceMain.showErrorAlert(this, error)
        }
    },
    async saveAddressBill() {
      try {
          await serviceMain.Delay(1000);
          let getDataLatLng = this.saveCurrentZipDatabill.find(e => e.SUBDISTRICT.trim() == this.billaddress.subdistrict && e.DISTRICT.trim() == this.billaddress.district && e.PROVINCE.trim() == this.billaddress.province)
          if(!getDataLatLng){
              throw new Error("ไม่สามารถเพิ่มที่อยู่ใบกำกับภาษีได้ (ที่อยู่ไม่ถูกต้อง)")
          }
          this.billaddress.ulat = getDataLatLng.LAT
          this.billaddress.ulong = getDataLatLng.LNG
          let data = {
              MODE: this.onEditbill,
              VIPID: this.dataSelected.VIPID,
              ADDRESS_JSON: this.billaddress,
              REF: ''
          }
          let getAPI = await serviceAPI.call_API('post', `checkout/SaveMultipleDlv`, data, 'auth');
          serviceMain.showSuccessAlertWithTime(this, `${this.onEdit ? 'แก้ไข' : 'เพิ่ม'}ที่อยู่ใบกำกับภาษี ${this.billaddress.adrname} แล้ว`, 1200)
      } catch (error) {
          serviceMain.showErrorAlert(this, error)
      }

    },
    async changZipcodeBill(para_zip) {
        try {
            if (para_zip.length == 5) {

                let data = {
                    ZIPCODE: para_zip
                }
                let getAPI = await serviceAPI.call_API('post', `checkout/getAddrbyZipcode`, data, 'auth');
                if (getAPI.data.dbrows == 0) {
                    throw new Error("ไม่พบรหัสไปรษณีย์ครับ")
                }
                let getData = getAPI.data.dbitems;
                this.saveCurrentZipDatabill = getData;
                let getTAMBON = [];
                let getDISTRICT = [];
                let getPROVINCE = [];
                let oldTAMBON = null;
                let oldDISTRICT = null;
                let oldPROVINCE = null;
                for (let e of getData) {
                    if (e.TAMBON != oldTAMBON) {
                        getTAMBON.push(e.TAMBON.trim())
                        oldTAMBON = e.TAMBON.trim();
                    }
                    if (e.DISTRICT != oldDISTRICT) {
                        getDISTRICT.push(e.DISTRICT.trim())
                        oldDISTRICT = e.DISTRICT.trim();
                    }
                    if (e.PROVINCE != oldPROVINCE) {
                        getPROVINCE.push(e.PROVINCE.trim())
                        oldPROVINCE = e.PROVINCE.trim();
                    }
                }

                this.zipDataBill = {
                    tbn: getTAMBON,
                    dst: getDISTRICT,
                    pvn: getPROVINCE
                }

                this.billaddress.subdistrict = this.billaddress.subdistrict || this.zipDataBill.tbn[0]
                this.billaddress.district = this.billaddress.district || this.zipDataBill.dst[0]
                this.billaddress.province = this.billaddress.province || this.zipDataBill.pvn[0]
                this.billaddress.isShowZip = true;
            } else {
                this.billaddress.isShowZip = false;
                this.zipDataBill = {
                    tbn: [],
                    dst: [],
                    pvn: []
                }
            }
        } catch (error) {
            serviceMain.showErrorAlert(this, error)
        }
    },
    async getAddress() {
        try {
            let data = {
                VIPID: this.dataSelected.VIPID
            };
            let getAPI = await serviceAPI.call_API('post', 'checkout/getMultiDlv', data, 'auth');
            this.listDataAddress = getAPI.data.dbitems;
            this.onEdit =  this.listDataAddress.find(itm => this.listData.address.shippingAddressId == itm.ANM) ? 'E' : 'N'
            this.onEditbill =  this.listDataAddress.find(itm => this.listData.billAddr.fulltaxCustomerId == itm.ANM) ? 'E' : 'N'
            if(this.listData.address.shippingAddressId == this.listData.billAddr.fulltaxCustomerId){
              this.address.adrstt = 'all'
              this.billaddress.adrstt = 'all'
            }
            else{
              this.address.adrstt = 'ship'
              this.billaddress.adrstt = 'bill'
            }
            // console.log(this.onEdit,this.onEditbill);
            // console.log(this.address.adrstt,this.billaddress.adrstt);
        } catch (error) {
            serviceMain.showErrorAlert(this, error)
        }
    },
  },
  computed: {
      getRef:function(){
          return this.$store.getters['customer/getQTCUSTREF']
      },
      getCopyRef:function(){
          return this.$store.getters['customer/getQTCOPYREF']
      },
      getQTBU: function () {
          return this.$store.getters['customer/getQTBU'];
      },
      qtDfStore: function () {
          return this.$store.getters['service/getQTDFSTORE'];
      },
      getQTSALESOURCE: function () {
          return this.$store.getters['auth/getQTSALESOURCE']
      },
      getQTSUBSALESOURCE: function () {
          return this.$store.getters['auth/getQTSUBSALESOURCE']
      },
      getQTETAX: function () {
          return this.$store.getters['auth/getQTETAX']
      },
      getDSMMSSTORE:function(){
        return this.$store.getters['service/getDSMMSSTORE']
      },
  }

}
</script>
<style scoped>

.cardWhite {
    margin: 0px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
}

.selectMail {
    border-radius: 15px !important;
    height: 35px;
    padding: 0px 15px;
    font-family: 'kittithadabold75regular';
    color: black;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
}

.form-control {
    height: 30px;
    padding: 0px 15px;
    width: 100% !important;
    border-radius: 4px;
    border: .5px solid #ccc;
    display: inline-block !important;
}

.col-regis {
    padding: 0px 20px !important;
}

.checkmark {
    top: 4px !important;
    height: 20px !important;
    width: 20px !important;
}

.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
}
.shadowDiv:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.register-input {
  width: 100% !important;
  border-radius: 4px;
  border: .5px solid #ccc;
  display: inline-block !important; 
}
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
</style>