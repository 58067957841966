<template>
  <section>
    <OrderFromAuto1 v-show="!isloading" ref="OrderFromRef" @getlistItems="getlistItems" @getshowRegister="getshowRegister" @getshowListItems="getshowListItems" @setisloading="setisloading"/>
    <RegisterformAuto1 v-show="!isloading" ref="RegisterFormRef" v-if="showRegister" :dataRegister="dataRegister" @confirmCreateUser="confirmCreateUser" @setisloading="setisloading"/>
    <OrderDetailAuto1 v-show="!isloading" v-if="showListItems" :listItems="listItems" :mode="mode" :orderNo="orderNo" :customerCar="customerCar" @setisloading="setisloading"/>
    <div v-show="isloading" class="loading"></div>
  </section>
</template>

<script>
// @ is an alias to /src
import OrderFromAuto1 from '@/components/OrderAuto1/OrderFromAuto1.vue'
import RegisterformAuto1 from '@/components/OrderAuto1/RegisterformAuto1.vue'
import OrderDetailAuto1 from '@/components/OrderAuto1/OrderDetailAuto1.vue'

export default {
  name: 'OrderAuto1Main',
  components: {
    OrderFromAuto1,
    RegisterformAuto1,
    OrderDetailAuto1
  },
  data: function () {
    return {
      orderNo:'',
      listItems:[],
      listUser:'',
      dataEmail:'',
      dataPhone:'',
      dataSelected: {
          CNAME: '',
          MOBILE: '',
          EMAIL: '',
          T1C: '',
          VIPID: '',
          SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
          EMPLOYEEBUSHORTCODE: '',
          EMPLOYEEID: '',
          ISSTAFF: '',
      },
      dataRegister: {
          MOBILE: "",
          EMAIL:"",
          CNAME:"",
          VIPID:"",
          T1C:"",
          SALESOURCE: "",
      },
      showRegister:false,
      showListItems:false,
      customerCar:'',
      isloading:false,
      mode:''
    }
  },
  mounted() {
  },
  methods: {
    async getlistItems(val) {
      try{
        this.listItems = val[1]
        this.dataRegister = val[0]
        this.orderNo = val[2]
        this.customerCar = val[3]
        this.mode = val[4]
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },
    async getshowRegister(val) {
      try{
        this.showRegister = val
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async getshowListItems(val) {
      try{
        this.showListItems = val
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    async setisloading(val) {
      try{
        this.isloading = val
      } catch (error) { 
        serviceMain.showErrorAlert(this,error)
      }
    },

    confirmCreateUser(){
      this.$refs.OrderFromRef.findUser();
      this.showRegister = false
    },

  },
}
</script>
<style>
.loading {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  background-image: url("/img/loadsmall.gif");
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
</style>
