<template>
  <div class="container">
    <OrderWholeSaleMain />
  </div>
</template>

<script>
// @ is an alias to /src
import OrderWholeSaleMain from '@/components/OrderWholeSale/OrderWholeSaleMain.vue'

export default {
  name: 'OrderWholeSale',
  components: {
    OrderWholeSaleMain,
  },
  data: function () {
    return {
      
    }
  },
  mounted() {
    document.title = `${process.env.VUE_APP_TITLE} - Order WholeSale`
    this.isFromCheckoutConfirm()
  },
  methods: {
    isFromCheckoutConfirm(){
      if(this.$route.query.confirm =='success'){
        this.$store.dispatch('customer/clearAfterConfirmCheckout')
        this.$store.dispatch('service/clearAfterConfirmCheckout')
      }
    }

  },
}
</script>
