<template>
<section class="container">
    <div v-if="!onEdit && !onAddNew" class="row">
        <div class="col-12">
            <div class="d-flex pt-3 justify-content-between">
                <div>
                    <h3 style="font-weight: bold;">จัดการที่อยู่</h3>
                </div>
            </div>
        </div>
        <div class='col-md-4 col-12 p-0 mx-0 my-1' style='max-height:150px'>
            <div class="card-body infobox h-100">
                <div class="mb-3">
                    <span :class="`brandcard font-color-${getBU}`" style="'kittithadabold65regular';">ที่อยู่จัดส่ง</span>
                    <hr class='m-1'>
                    <span class="namecard" style=": 'kittithadabold65regular';">{{deliveryAddress?.ADNUM ? `${deliveryAddress.ADNUM.trim()}, ${deliveryAddress.ROAD.trim()}, ${deliveryAddress.SOI.trim()}, ${deliveryAddress.TBN.trim()}, ${deliveryAddress.DST.trim()}, ${deliveryAddress.PVN.trim()}, ${deliveryAddress.ZIP.trim()}` : '-'}}</span>
                </div>
            </div>
        </div>
        <div class='col-md-4 col-12 p-0 mx-0 my-1' style='max-height:150px'>
            <div class="card-body infobox h-100">
                <div class="mb-3">
                    <span :class="`brandcard font-color-${getBU}`" style="'kittithadabold65regular';">ที่อยู่ใบกำกับภาษี</span>
                    <hr class='m-1'>
                    <span class="namecard" style=": 'kittithadabold65regular';">{{taxAddress?.ADNUM ? `${taxAddress.ADNUM.trim()}, ${taxAddress.ROAD.trim()}, ${taxAddress.SOI.trim()}, ${taxAddress.TBN.trim()}, ${taxAddress.DST.trim()}, ${taxAddress.PVN.trim()}, ${taxAddress.ZIP.trim()}` : '-'}}</span>
                </div>
            </div>
        </div>
        <div class="col-12">
            <div class="d-flex pt-3 justify-content-between">
                <div>
                    <h3 style="font-weight: bold;">ที่อยู่ทั้งหมด</h3>
                </div>
            </div>
        </div>
        <div class='col-md-4 col-xs-12 p-0 mx-0 my-1' style='max-height:150px' v-for="(e,index) in listData" :key="index" :value="e.ANM">
            <div class="card-body infobox h-100">
                <div class="mb-3">
                    <span id="cardid" :class="`brandcard font-color-${getBU}`" style="'kittithadabold65regular';">{{e.ANM}} </span>
                    <span @click="editAddress(e)" class="editAddressLink">แก้ไข</span>
                    <span @click="deleteAddress(e)" class="delAddress"><i class="fa fa-trash" aria-hidden="true"></i></span>
                    <hr class='m-1'>
                    <span id="cardname" class="namecard" style=": 'kittithadabold65regular';">{{e.ADNUM.trim()}}, {{e.ROAD.trim()}}, {{e.SOI.trim()}}, {{e.TBN.trim()}}, {{e.DST.trim()}}, {{e.PVN.trim()}}, {{e.ZIP.trim()}}</span>
                </div>
            </div>
        </div>
            <div class="col-md-4 col-xs-12 p-0 mx-0 my-1 newaddbox">
                <div class="m-1 h-100">
                    <div class="mb-3">
                        <button id="addnewaddr" @click="newAddress()">
                            <div class="addnew">+ เพิ่มที่อยู่ใหม่</div>
                        </button>
                    </div>
                </div>
            </div>
    </div>

    <div v-if="onEdit || onAddNew" class="row card">
        <div class="col-12">
            <div class="d-flex pt-3 justify-content-between">
                <div>
                    <p class="h4">
                        {{address.adrname?`แก้ไขที่อยู่ "${address.adrname}"`:'เพิ่มที่อยู่'}}</p>
                </div>
                <div>
                    <button @click="cancleEdit" :class="`btn-visible-${getBU}`">ยกเลิก</button>
                </div>
            </div>
        </div>
        <div class="row px-2 ">
            <div class="form-group col-6">
                <label for="nameadd" :class="`font-color-${getBU} font-weight-bold`">ชื่อ<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                <input v-model="address.name" type="text" class="form-control" name="nameadd" placeholder="ชื่อ" autocomplete="off">
            </div>
            <div class="form-group col-6">
                <label for="lnameadd" :class="`font-color-${getBU} font-weight-bold`">นามสกุล<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                <input v-model="address.lname" type="text" class="form-control" name="lnameadd" placeholder="นามสกุล" autocomplete="off">
            </div>
            <div class="form-group col-6">
                <label for="phoneadd" :class="`font-color-${getBU} font-weight-bold`">เบอร์ติดต่อ<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                <input v-model="address.phone" type="text" class="form-control" name="phoneadd" placeholder="" maxlength="10" autocomplete="off">
            </div>
            <div class="form-group col-6">
                <label for="phone2add" :class="`font-weight-bold`">เบอร์ติดต่อ (สำรอง)</label>
                <input v-model="address.phone2" type="text" class="form-control" name="phone2add" placeholder="" maxlength="10" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="adrnumadd" :class="`font-color-${getBU} font-weight-bold`">บ้านเลขที่<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                <input v-model="address.adrnum" type="text" class="form-control" name="adrnumadd" placeholder="บ้านเลขที่" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="buildadd" :class="`font-weight-bold`">หมู่บ้าน/อาคาร</label>
                <input v-model="address.build" type="text" class="form-control" name="buildadd" placeholder="หมู่บ้าน/อาคาร" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="flooradd" :class="`font-weight-bold`">ชั้นที่</label>
                <input v-model="address.floor" type="text" class="form-control" name="flooradd" placeholder="ชั้นที่" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="roomadd" :class="`font-weight-bold`">ห้องที่</label>
                <input v-model="address.room" type="text" class="form-control" name="roomadd" placeholder="ห้องที่" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="mooadd" :class="`font-weight-bold`">หมู่ที่</label>
                <input v-model="address.moo" type="text" class="form-control" name="mooadd" placeholder="หมู่ที่" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="soiadd" :class="`font-weight-bold`">ซอย</label>
                <input v-model="address.soi" type="text" class="form-control" name="soiadd" placeholder="ซอย" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="roadadd" :class="`font-weight-bold`">ถนน</label>
                <input v-model="address.road" type="text" class="form-control" name="roadadd" placeholder="ถนน" autocomplete="off">
            </div>
            <div class="form-group col-6 col-md-3">
                <label for="zipadd" :class="`font-color-${getBU} font-weight-bold`">รหัสไปรษณีย์</label><span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                <input v-model="address.zipcode" type="text" class="form-control" @keyup="changZipcode(address.zipcode)" name="zipadd" placeholder="รหัสไปรษณีย์" maxlength="5" autocomplete="off">
            </div>
            <div v-if="address.isShowZip" class="col-12">
                <div class="row">
                    <div class="form-group col-6 col-md-4">
                        <label for="subdistrictadd" :class="`font-color-${getBU} font-weight-bold`">ตำบล</label><span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                        <select v-model="address.subdistrict" name='subdistrictadd' class="form-control">
                            <option v-for="(e, index) in zipData.tbn" :key="index" :value="e">{{e}}</option>
                        </select>
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <label for="districtadd" :class="`font-color-${getBU} font-weight-bold`">อำเภอ</label><span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                        <select v-model="address.district" name='districtadd' class="form-control">
                            <option v-for="(e, index) in zipData.dst" :key="index" :value="e">{{e}}</option>
                        </select>
                    </div>
                    <div class="form-group col-6 col-md-4">
                        <label for="provinceadd" :class="`font-color-${getBU} font-weight-bold`">จังหวัด</label><span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                        <select v-model="address.province" name="provinceadd" class="form-control">
                            <option v-for="(e, index) in zipData.pvn" :key="index" :value="e">{{e}}</option>
                        </select>
                    </div>
                    <!-- <div class="form-group col-12">
                        <label for="provinceadd" :class="`font-color-${getBU} font-weight-bold`">ตำบล / อำเภอ / จังหวัด</label><span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                        <select v-model="zipData1" @change="onChange()" name="provinceadd" class="form-control">
                            <option v-for="(e, index) in saveCurrentZipData" :key="index" :value="e">{{e.TAMBON}} / {{e.DISTRICT}} / {{e.PROVINCE}}</option>
                        </select>
                    </div> -->
                </div>
            </div>
        </div>
        <hr v-if="address.isDeliveryAddress || !address.isTaxAddress">
        <div class="row px-2 w-100" v-if="address.isDeliveryAddress || !address.isTaxAddress">
            <div class="form-group col-12">
                <div class="row p-0">
                    <div class="col-12 col-md-4">
                        <label :class="`font-color-${getBU} font-weight-bold`"> ลักษณะที่อยู่อาศัย</label>
                        <label class="containerq"> บ้านเดี่ยว
                            <input v-model="address.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="ban">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> คอนโดฯ
                            <input v-model="address.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="condo">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> ทาวน์เฮ้าส์
                            <input v-model="address.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="townho">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> อพาทเมนท์
                            <input v-model="address.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="apart">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <div class="row">
                            <div class="col-5 " style="padding-right: 0;">
                                <label class="containerq"> อื่นๆ
                                    <input v-model="address.typeofadr" type="radio" class="varckradio" name="typeofadradd" value="other">
                                    <span :class="`checkmark checkmark-color-${getBU}`"></span>
                                </label>
                            </div>
                            <div class="col-7 " style="padding-left: 0;">
                                <input v-model="address.othertxt" type="text" class="form-control" name="othertxtadd" placeholder="" maxlength="20" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <label :class="`font-color-${getBU} font-weight-bold`"> มีลิฟท์ส่งสินค้าหรือไม่</label>
                        <label class="containerq"> มี
                            <input v-model="address.havele" type="radio" class="varckradio" name="haveleadd" value="1">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> ไม่มี
                            <input v-model="address.havele" type="radio" class="varckradio" name="haveleadd" value="0">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                    </div>
                    <div class="col-12 col-md-4">
                        <label :class="`font-color-${getBU} font-weight-bold`"> รถที่สามารถเข้าพื้นที่ได้</label>
                        <label class="containerq"> รถ 4 และ 6 ล้อเข้าได้
                            <input v-model="address.can" type="radio" class="varckradio" name="canadd" value="1">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> รถ 4 ล้อเข้าได้
                            <input v-model="address.can" type="radio" class="varckradio" name="canadd" value="2">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> รถ 6 ล้อเข้าได้
                            <input v-model="address.can" type="radio" class="varckradio" name="canadd" value="3">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <label class="containerq"> รถ 4 และ 6 ล้อเข้าไม่ได้
                            <input v-model="address.can" type="radio" class="varckradio" name="canadd" value="4">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row px-2 w-100">
            <div class="form-group col-12">
                <label class="containerb"> ตั้งเป็นค่าเริ่มต้นที่อยู่จัดส่ง
                    <input type="checkbox" class="varckradio" v-model="address.isDeliveryAddress" @change="changeAddressType">
                    <span :class="`checkmarkbox checkmark-color-${getBU}`"></span>
                </label>
                <label class="containerb"> ตั้งเป็นค่าเริ่มต้นใบกำกับภาษี
                    <input type="checkbox" class="varckradio" v-model="address.isTaxAddress" @change="changeAddressType">
                    <span :class="`checkmarkbox checkmark-color-${getBU}`"></span>
                </label>
            </div>
            <div class="form-group col-12" v-if="address.isTaxAddress">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <label class="containerq"> นิติบุคคล
                            <input type="radio" v-model='address.iscom' class="varckradio" name="iscomadd"  value="1" @change="changeIsCom">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                    </div>
                    <div class="col-12 col-md-4">
                        <label class="containerq"> บุคคลธรรมดา
                            <input type="radio" v-model='address.iscom' class="varckradio" name="iscomadd" value="0" @change="changeIsCom">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <label :class="`font-color-${getBU} font-weight-bold`">{{ address.iscom == 0 ? 'เลขบัตรประจำตัวประชาชน' : 'เลขที่ประจำตัวผู้เสียภาษี' }}<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                        <input type="text" class='form-control' v-model="address.idnum" maxlength="13" placeholder="หมายเลข 13 หลัก" autocomplete="off">
                    </div>
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-4"></div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4" v-if="address.iscom == 1">
                        <label :class="`font-color-${getBU} font-weight-bold`">บริษัท<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                        <input type="text" class='form-control' v-model="address.company" placeholder="บริษัท" autocomplete="off">
                    </div>
                    <div class="col-12 col-md-4" v-if="address.iscom == 1">
                        <label :class="`font-color-${getBU} font-weight-bold`">ประเภทสาขา<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                        <label class="containerq"> สำนักงานใหญ่
                            <input type="radio" v-model='address.ishq' class="varckradio" name="ishq" value="Y" @change="changeHQ">
                            <span :class="`checkmark checkmark-color-${getBU}`"></span>
                        </label>
                        <div class="row">
                            <div class="col-5 " style="padding-right: 0;">
                                <label class="containerq"> สาขา
                                    <input type="radio" v-model='address.ishq' class="varckradio" name="ishq" value="N" @change="changeHQ">
                                    <span :class="`checkmark checkmark-color-${getBU}`"></span>
                                </label>
                            </div>
                            <div class="col-7 " style="padding-left: 0;" v-if='address.ishq == "N"'>
                                <input v-model="address.idbranch" type="text" class="form-control" placeholder="เช่น 00011" maxlength="5" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4" v-if="address.iscom == 0">
                        <label :class="`font-color-${getBU} font-weight-bold`">ชื่อ<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                        <input type="text" class='form-control' v-model="address.namenocom" placeholder="ชื่อ" autocomplete="off">
                    </div>
                    <div class="col-12 col-md-4" v-if="address.iscom == 0">
                        <label :class="`font-color-${getBU} font-weight-bold`">นามสกุล<span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span></label>
                        <input type="text" class='form-control' v-model="address.lnamenocom" placeholder="นามสกุล" autocomplete="off">
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row px-2 w-100">
            <div class="form-group col-12 col-md-4">
                <label for="adrnameadd" :class="`font-color-${getBU} font-weight-bold`">ตั้งชื่อที่อยู่นี้</label>
                <span style="margin: 0px;font-size:.9em;" :class="`font-color-${getBU} font-weight-bold`">*</span>
                <input v-model="address.adrname" type="text" class="form-control" name="adrnameadd" maxlength="20" placeholder="บ้านฉัน,บ้านคุณพ่อ,ออฟฟิศ,..." :disabled="onEdit">
            </div>
        </div>
        <hr>
        <div class="row px-2">
            <div class="col-12">
                <button @click="saveAddress" :class="`btn-visible-${getBU}`">{{onAddNew ? 'เพิ่ม' : 'แก้ไข'}}ที่อยู่นี้</button>
            </div>
        </div><br>
    </div>
    <br>
</section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';

export default {
    name: 'AddressManagementList',
    components: {

    },
    props: [

    ],
    data: function () {
        return {
            listData: [],
            onAddNew: false,
            onEdit: false,
            zipChange: false,
            zipData: {
                tbn: [],
                dst: [],
                pvn: []
            },
            zipData1: [],
            saveCurrentZipData:[],
            addFormDisplay: {
                isAddressDlv: false,
                isAddressTax: false,
                isCompany: false,
                isShowZip: false
            },
            deliveryAddress: {},
            taxAddress: {},
            address: {
                adrname: '',
                name: '',
                lname: '',
                iscom: 1,
                company: '',
                namenocom: '',
                lnamenocom: '',
                idnum: '',
                idbranch: '',
                phone: '',
                phone2: '',
                adrnum: '',
                build: '',
                floor: '',
                room: '',
                moo: '',
                soi: '',
                road: '',
                subdistrict: '',
                district: '',
                province: '',
                zipcode: '',
                ulat: '',
                ulong: '',
                typeofadr: '',
                othertxt: '',
                havele: '',
                can: '',
                adrstt: '',
                ishq: 'Y',
                isDeliveryAddress: false,
                isTaxAddress: false,
                isCompany: false,
                isShowZip: false
            },
            ListError: new Error("กรอกข้อมูลไม่ครบถ้วน")
        }
    },
    mounted() {
        this.getAddress()
    },
    methods: {
        async getAddress() {
            try {
                let data = {
                    VIPID: this.getQTCUSTINFO.VIPID
                };
                let getAPI = await serviceAPI.call_API('post', 'checkout/getMultiDlv', data, 'auth');
                this.listData = getAPI.data.dbitems;

                this.deliveryAddress = this.listData.find(itm => ['all', 'ship'].includes(itm.STT))
                this.taxAddress = this.listData.find(itm => ['all', 'bill'].includes(itm.STT))
            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }
        },
        async validationError(field, incorrectValue, message) {
            if (this.address[field] == incorrectValue) {
                this.ListError.validation.push({
                    msg: message
                })
            }
        },  
        async saveAddress() {
            try {
                this.ListError.validation = []
                await this.validationError('name', '', "กรุณากรอกชื่อ-สกุลให้ครบถ้วน")
                await this.validationError('lname', '', "กรุณากรอกชื่อ-สกุลให้ครบถ้วน")
                await this.validationError('phone', '', "กรุณากรอกเบอร์ติดต่อ")
                await this.validationError('adrnum', '', "กรุณากรอกบ้านเลขที่")
                await this.validationError('zipcode', '', "กรุณากรอกรหัสไปรษณีย์")
                await this.validationError('subdistrict', '', "กรุณากรอกตำบล")
                await this.validationError('district', '', "กรุณากรอกอำเภอ")
                await this.validationError('province', '', "กรุณากรอกจังหวัด")

                if(this.address['phone'].length != 10){
                    throw new Error("เบอร์ติดต่อไม่ครบ10หลัก")
                }

                if(this.address.isTaxAddress) {
                    if(this.address['idnum'].length != 13){
                        throw new Error(this.address.iscom == 0 ? "เลขบัตรประจำตัวประชาชนไม่ครบ13หลัก" : "เลขที่ประจำตัวผู้เสียภาษีไม่ครบ13หลัก")
                    }
                    await this.validationError('idnum', '', this.address.iscom == 0 ? "กรุณากรอกเลขบัตรประจำตัวประชาชน" : "กรุณากรอกเลขที่ประจำตัวผู้เสียภาษี")
                    if(this.address.iscom == 0) {
                        await this.validationError('namenocom', '', "กรุณากรอกชื่อ สำหรับส่วนใบกำกับภาษี")
                        await this.validationError('lnamenocom', '', "กรุณากรอกนามสกุล สำหรับส่วนใบกำกับภาษี")
                    } else {
                        await this.validationError('company', '', "กรุณากรอกชื่อบริษัท")
                        if (this.address.ishq == 'N') 
                            await this.validationError('idbranch', '', "กรุณากรอกลำกับที่สาขา")
                    }
                }

                await this.validationError('adrname', '', "กรุณากรอกชื่อที่อยู่")
                
                if (this.ListError.validation.length != 0) {
                    throw this.ListError
                }

                if (this.zipChange == false) {
                    this.changZipcode(this.address.zipcode)
                }
                await serviceMain.Delay(1000);
                let getDataLatLng = this.saveCurrentZipData.find(e => e.TAMBON.trim() == this.address.subdistrict)
                this.address.ulat = getDataLatLng.LAT
                this.address.ulong = getDataLatLng.LNG
                // this.address.ulat = this.zipData1.LAT
                // this.address.ulong = this.zipData1.LNG
                let data = {
                    MODE: this.onEdit ? 'E' : 'N',
                    VIPID: this.getQTCUSTINFO.VIPID,
                    ADDRESS_JSON: this.address,
                    REF: ''
                }
                let getAPI = await serviceAPI.call_API('post', `checkout/SaveMultipleDlv`, data, 'auth');
                serviceMain.showSuccessAlertWithTime(this, `${this.onEdit ? 'แก้ไข' : 'เพิ่ม'}ที่อยู่ ${this.address.adrname} แล้ว`, 1200)
                this.onEdit = false;
                this.onAddNew = false;
                this.refreshAddressForm()
                this.getAddress()
                $(window).scrollTop(0);
            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }

        },

        async deleteAddress(jsnAddress) {
            this.address = await this.addressSeleted(jsnAddress)
            let delIdx = this.listData.findIndex(itm => itm.adrname == jsnAddress.adrname);
            
            this.$swal.fire({
              html: `<h3>คุณต้องการลบที่อยู่ ${this.address.adrname}? </h3>`,
              showCancelButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: this.getBuColor,
              cancelButtonColor: '#B4B4B4',
              confirmButtonText: 'ยืนยัน',
              cancelButtonText: 'ยกเลิก'
          }).then(async (result) => {
              if (result.value) {
                let data = {
                    MODE: 'D',
                    VIPID: this.getQTCUSTINFO.VIPID,
                    ADDRESS_JSON: this.address,
                    REF: ''
                }
                let getAPI = await serviceAPI.call_API('post', `checkout/SaveMultipleDlv`, data, 'auth');
                this.listData.splice(delIdx, 1)
                this.refreshAddressForm()
                this.getAddress()
              }
          });
        },

        async editAddress(jsnAddress) {
            this.onEdit = true;
            this.address = await this.addressSeleted(jsnAddress)
            if (this.address.zipcode) {
                await this.changZipcode(this.address.zipcode)
            }
            // this.zipData1 = this.saveCurrentZipData.find(e => e.TAMBON.trim() == this.address.subdistrict)
        },
        async addressSeleted(jsnAddress) {
            let { ANM, NME, LNME, ISCOM, COM, NNC, LNNC, IDN, IDB, PHO, PHO2, ADNUM, BLD, FLR, ROM, MOO, SOI, ROAD, TBN, 
                DST, PVN, ZIP, LAT, LNG, TOA, OTT, HVEL, CAN, STT, ISHQ } = {...jsnAddress}
            return {
                adrname: ANM,
                name: NME,
                lname: LNME,
                iscom: ISCOM || (COM ? 1 : 0),
                company: COM,
                namenocom: NNC,
                lnamenocom: LNNC,
                idnum: IDN,
                idbranch: IDB,
                phone: PHO,
                phone2: PHO2,
                adrnum: ADNUM,
                build: BLD,
                floor: FLR,
                room: ROM,
                moo: MOO,
                soi: SOI,
                road: ROAD,
                subdistrict: TBN,
                district: DST,
                province: PVN,
                zipcode: ZIP,
                ulat: LAT,
                ulong: LNG,
                typeofadr: TOA,
                othertxt: OTT,
                havele: HVEL,
                can: CAN,
                adrstt: STT,
                ishq: ISHQ || 'N',
                isDeliveryAddress: STT && ['all', 'ship'].includes(STT),
                isTaxAddress: STT && ['all', 'bill'].includes(STT)
            }
        },
        async cancleEdit() {
            this.refreshAddressForm()
            this.onEdit = false;
            this.onAddNew = false;
        },

        async newAddress() {
            this.onAddNew = true;
            this.address.name = this.getQTCUSTINFO.CNAME
            // this.address.lname = this.getQTCUSTINFO
            this.address.phone = this.getQTCUSTINFO.MOBILE
        },

        async changZipcode(para_zip) {
            this.zipChange = true;
            try {
                if (para_zip.length == 5) {

                    let data = {
                        ZIPCODE: para_zip
                    }
                    let getAPI = await serviceAPI.call_API('post', `checkout/getAddrbyZipcode`, data, 'auth');
                    if (getAPI.data.dbrows == 0) {
                        throw new Error("ไม่พบรหัสไปรษณีย์ครับ")
                    }
                    let getData = getAPI.data.dbitems;
                    this.saveCurrentZipData = getData;
                    let getTAMBON = [];
                    let getDISTRICT = [];
                    let getPROVINCE = [];
                    let oldTAMBON = null;
                    let oldDISTRICT = null;
                    let oldPROVINCE = null;
                    for (let e of getData) {
                        if (e.TAMBON != oldTAMBON) {
                            getTAMBON.push(e.TAMBON.trim())
                            oldTAMBON = e.TAMBON.trim();
                        }
                        if (e.DISTRICT != oldDISTRICT) {
                            getDISTRICT.push(e.DISTRICT.trim())
                            oldDISTRICT = e.DISTRICT.trim();
                        }
                        if (e.PROVINCE != oldPROVINCE) {
                            getPROVINCE.push(e.PROVINCE.trim())
                            oldPROVINCE = e.PROVINCE.trim();
                        }
                    }
                    // this.zipData1 = getData

                    this.zipData = {
                        tbn: getTAMBON,
                        dst: getDISTRICT,
                        pvn: getPROVINCE
                    }

                    this.address.subdistrict = this.address.subdistrict || this.zipData.tbn[0]
                    this.address.district = this.address.district || this.zipData.dst[0]
                    this.address.province = this.address.province || this.zipData.pvn[0]
                    this.address.isShowZip = true;
                } else {
                    this.address.isShowZip = false;
                    this.zipData = {
                        tbn: [],
                        dst: [],
                        pvn: []
                    }
                }
            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }
        },

        async onChange() {
            this.address.subdistrict = this.zipData1.TAMBON.trim()
            this.address.district = this.zipData1.DISTRICT.trim()
            this.address.province = this.zipData1.PROVINCE.trim()
        },

        refreshAddressForm() {
            this.address = {
                adrname: '',
                name: '',
                lname: '',
                iscom: 1,
                company: '',
                namenocom: '',
                lnamenocom: '',
                idnum: '',
                idbranch: '',
                phone: '',
                phone2: '',
                adrnum: '',
                build: '',
                floor: '',
                room: '',
                moo: '',
                soi: '',
                road: '',
                subdistrict: '',
                district: '',
                province: '',
                zipcode: '',
                ulat: '',
                ulong: '',
                typeofadr: '',
                othertxt: '',
                havele: '',
                can: '',
                adrstt: '',
                ishq: 'Y',
                isDeliveryAddress: false,
                isTaxAddress: false,
                isCompany: false,
                isShowZip: false
            }
        },
        async changeAddressType (){
            this.address.adrstt = ''
            if(this.onEdit || this.onAddNew) {
                if (this.address.isDeliveryAddress && this.address.isTaxAddress) 
                    this.address.adrstt = 'all'
                else if (this.address.isDeliveryAddress) 
                    this.address.adrstt = 'ship'
                else if (this.address.isTaxAddress){
                    this.address.adrstt = 'bill'
                    this.address.havele = ''
                    this.address.can = ''
                    this.address.typeofadr = ''
                }
                
                if(!this.address.isTaxAddress) {
                    this.address.idnum = ''
                    this.address.company = ''
                    this.address.ishq = 'Y'
                    this.address.idbranch = ''
                    this.address.namenocom = ''
                    this.address.lnamenocom = ''
                }
            }
        },
        async changeHQ (){
            if(this.address.ishq == 'Y') 
                this.address.idbranch = ''
        },
        async changeIsCom() {
            this.address.idnum = ''
            if(this.address.iscom == 0) {
                this.address.company = ''
                this.address.ishq = 'Y'
                this.address.idbranch = ''
            } else {
                this.address.namenocom = ''
                this.address.lnamenocom = ''
            }
        }
    },
    computed: {
        getQTCUSTINFO: function () {
            return this.$store.getters['customer/getQTCUSTINFO']
        },
        getBU: function () {
            return this.$store.getters['customer/getQTBU']
        },
        CartRef: function () {
            return this.$store.getters['customer/getQTCUSTREF']
        },
    },
    watch: {

    }
}
</script>

<style lang="scss" scoped>
.newaddbox button {
    background-color: transparent;
    border-radius: 8px;
    height: 150px;
    width: 100%;
    outline: 0;
    border-style: dashed;
    border-color: gray;
}

.newaddbox .addnew {
    color: gray;
}

.container {
    min-height: 0 !important;
}

.delAddress {
    font-size: 0.8em !important;
    color: rgb(255, 255, 255);
    text-align: center;
    letter-spacing: .025em;
    border-radius: 50%;
    background-color: gray;
    width: 30px;
    height: 30px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    right: 20px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;

    &:hover {
        background-color: red;
        cursor: pointer;
    }
}

.editAddressLink {
    font-size: 0.8em !important;
    color: gray;
    text-align: center;
    letter-spacing: .025em;
    width: 30px;
    height: 30px;
    line-height: 15px;
    position: absolute;
    transition: all .2s ease-out;
    right: 60px;
    padding-top: 7px;
    font-family: 'kittithadabold75regular' !important;

    &:hover {
        color: red;
        cursor: pointer;
    }
}

.brandcard {
    color: red;
    font-weight: bold;

}

.infobox {
    margin: 5px 5px;
    padding: 20px;
    background-color: #fff;
    border-radius: 7px;
}

.labelshowaddress {
    font-family: 'kittithadabold75regular';
}

.pshowaddress {
    border-bottom: 1px solid gray;
    height: 32px;
}

.form-group {
    margin-bottom: 5px;
}

label {
    margin-bottom: 0px;
    color: rgb(125, 125, 125);
    font-size: .9em;
}

.card {
    background-color: white;
    border: none;

}

.form-control {
    height: 1.5rem;
    padding: 2px 5px;
}

.select-address {
    padding: 0px 15px;
    font-family: 'kittithadabold75regular';
    color: black;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
}

.containerq {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.containerq input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.containerq:hover input~.checkmark {
    background-color: #ccc;
}

.containerq input:checked~.checkmark {
    background-color: white;
    border-style: solid;
    // border-color: #55246c;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.containerq input:checked~.checkmark:after {
    display: block;
}

.containerq .checkmark:after {
    top: 4px;
    left: 4px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    // background: #55246c;

}

.varckradio {
    float: left;
}

input[err="true"] {
    border: 2px red solid !important;
}

select[err="true"] {
    border: 2px red solid !important;
}

.box-black {
    border: 2px black solid !important;
    border-radius: 8px;
}

.containerb {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.containerb input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmarkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: rgb(255, 255, 255);
    border-style: solid;
    border-color: #999999;
}

/* On mouse-over, add a grey background color */
.containerb:hover input~.checkmarkbox {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerb input:checked~.checkmarkbox {
    background-color: #ffffff;
    border-style: solid;
    // border-color: #55246c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerb input:checked~.checkmarkbox:after {
    display: block;
}

/* Style the checkmark/indicator */
.containerb .checkmarkbox:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    // border: solid #55246c;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
