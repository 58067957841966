<template>
      <span>{{ prName }}</span>
</template>


<script>
  import * as serviceAPI from '../services/API.service';


  export default {
    name: 'PrNameBySKU',
    data: function () {
      return {
        prName:''
      }
    },
    mounted() {
      this.showprName()
    },
    props: [
      "sku",
      "buCode"
    ],
    methods: {
      async showprName(){
        try {
          let data={}
          let getAPI = await serviceAPI.call_API('get',`service/prname/${this.sku}`,data,'auth');
          let getData =  getAPI.data.dbitems[0].prname
          this.prName = getData;
      
        } catch (error) {
          
        }
      }
    },
    computed: {
      getBU: function () {
          return this.$store.getters['customer/getQTBU']
      },
    }
  }
       
</script>

<style scoped>

</style>