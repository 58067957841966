<template>
  <section  >
    <div class="row px-3 m-0 mb-3 card-white" v-show="false">
      <div class="col-12 mb-2">
        <p class="h4 ml-3 mt-3">รายละเอียดสินค้า</p>
        <p class="h5 ml-3 mt-3">สินค้าในตะกร้า</p>
        <table class="table table-borderless " >
          <thead>
            <tr>
              <th scope="col">สินค้า</th>
              <th scope="col"></th>
              <th scope="col">จำนวน</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e, index) in listcart" :key="index">
              <td class="p-0">
                <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.SKCODE" :key="e.SKCODE" v-if="!(e.SKCODE == '')"/>
              </td>
              <td class="py-1">
                {{ e.PRN }}
                <br>({{ e.SKCODE }})
              </td>
              <td class="py-1">{{ e.QNT }}</td>
            </tr>
          </tbody>
        </table>
        <p class="h5 ml-3 mt-3">สินค้าในใบรายการ</p>
        <table class="table table-borderless ">
          <thead>
            <tr>
              <th scope="col">สินค้า</th>
              <th scope="col"></th>
              <th scope="col">จำนวน</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(e, index) in listItems" :key="index">
              <td class="p-0">
                <ImageBySKU class="py-1" style="max-height: 10vh;" :sku="e.Sku" :key="e.Sku" v-if="!(e.Sku == '')"/>
              </td>
              <td class="py-1">
                <PrNameBySKU :sku="e.Sku" :key="e.Sku" v-if="!(e.Sku == '')"/>
                <br>({{ e.Sku }})
              </td>
              <td class="py-1">{{ e.Quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-6">
            <!-- <button type="button" :class="`twdorbnb btn-${getBU}`" style=" " @click="clearCart()"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ล้างตะกร้า</strong></button> -->
          </div>
          <div class="col-12 col-md-6">
            <button type="button" :class="`twdorbnb btn-${getBU}`" style=" " @click="addSku()"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">เพิ่มสินค้า</strong></button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import Modal from '@/components/Modal.vue'
import ImageBySKU from '@/components/ImageBySKU.vue';
import PrNameBySKU from '@/components/PrNameBySKU.vue';

export default {
  name: "OrderDetailAuto1",
  data: function() {
    return {
      listcart:[],
    };
  },
  components: {
    Modal,
    ImageBySKU,
    PrNameBySKU,
  },
  props: [
    "listItems",
    "orderNo",
    "customerCar",
    "mode"
  ],
  mounted() {
    this.Init()
    
    // this.showListCart();
  },
  methods: {
    async Init(){
      console.log(this.mode);
      if(this.mode != 'A'){
        await this.addSku()
      }else{
        serviceMain.showSuccessAlertWithTime(this,`เลือกใบงานสำเร็จ`,1000)
        setTimeout(() => { window.location.href = `../`; }, 1500);
        // this.$router.push({
        //     name: 'Cart'
        // });
      }
    },
    async addSku() {
      try {
        if(!this.getQTCUSTREF){
          this.$emit("setisloading",false);
          throw new Error("กรุณาเลือกตะกร้าก่อนครับ")
        }
        if(!this.getQTDFSTORE){
          this.$emit("setisloading",false);
          throw new Error("กรุณาเลือกสาขา")
        }
        let dataupdate={
          REF:this.getQTCUSTREF,
          ORDERNO:this.orderNo
        }
        let getAPI = await serviceAPI.call_API('post',`service/updateCopyFrom`,dataupdate,'auth');
        // console.log(getAPI);
        this.updateCustCar()
        for (let i = 0; i < this.listItems.length; i++) {
          let data = {
            "REF":this.getQTCUSTREF , 
            "SKU":this.listItems[i].Sku, 
            "STCODE":this.getQTDFSTORE , 
            "QNT":this.listItems[i].Quantity?this.listItems[i].Quantity:1
          }
          let getAPI = await serviceAPI.call_API('post', `cart/addtocart`, data,'auth');
          let getData = getAPI.data.dbitems[0]
          this.$store.dispatch('customer/setQTSHOPITEM',getData.SUMQNT)
        }
        serviceMain.showSuccessAlertWithTime(this,`เพิ่มสินค้าสำเร็จ`,1000)
        setTimeout(() => { window.location.href = `../cart`; }, 1500);
        // this.$router.push({
        //     name: 'Cart'
        // });
        // this.showListCart()
      } catch (error) {
        this.clearCart()
        this.$emit("setisloading",false);
        serviceMain.showErrorAlert(this, error.message)
      }
    },
    
    async showListCart() {
      try {
        if(!this.getQTCUSTREF){
          return
        }
        if(!this.getQTDFSTORE){
          return
        }
        let data = {
          REF: this.getQTCUSTREF,
          STCODE: this.getQTDFSTORE,
        };
        let getAPI = await serviceAPI.call_API("post","cart/getcartbundle",data,"auth");
        // console.log(getAPI);
        this.listcart = getAPI.data.dbitems.map((e) => ({
          ...e,
          NEWUPC: e.UPC.replace(/,/g, ""),
          NEWCOST: e.COST.replace(/,/g, ""),
        }));
      } catch (error) {
        serviceMain.showErrorAlert(this, error.message);
      }
    },
    async clearCart() {
      try {
        if(this.getQTCUSTREF){
          let data = {
            REF: this.getQTCUSTREF,
          };
          let getAPI = await serviceAPI.call_API("post",`cart/clearCart`,data,"auth");
          this.qtShopItem = 0;
        }
      } catch (error) {
        this.$emit("setisloading",false);
        serviceMain.showErrorAlert(this, error.message);
      }
    },
    async genNewCartRef() {
      let data = {
        MCID: this.$store.getters["customer/getQTMCID"],
        EMAIL: this.getQTCUSTINFO.EMAIL,
        MOBILE: this.getQTCUSTINFO.MOBILE,
        USERID: this.$store.getters["auth/getQTUSERID"],
      };
      let getAPI = await serviceAPI.call_API("post","user/getCustref",data,"auth");
      let getData = getAPI.data.dbitems[0];
      let getCUSTREF = getData.CUSTREF;
      this.$store.dispatch("customer/setQTCUSTREF", getCUSTREF);
      this.$store.dispatch("customer/setQTDLVTRTYPE", "");
      this.showListCart()
    },
    async updateRemark(){
        try {
            let data={
                REF:this.getQTCUSTREF ,
                REMARK:`ทะเบียนรถ : ${this.customerCar}` ,
                STCODE:this.getQTDFSTORE
            }
            console.log(data);
            let getAPI = await serviceAPI.call_API('post',`checkout/updateInfoScantrans`,data,'auth');
        } catch (error) {
            this.$emit("setisloading",false);
            serviceMain.showErrorAlert(this,error)
        }
    },
    async updateCustCar(){
        try {
            let data={
                REF:this.getQTCUSTREF ,
                REMARK2:JSON.stringify(this.customerCar) ,
            }
            console.log(data);
            let getAPI = await serviceAPI.call_API('post',`at1Order/updateCustomerCar`,data,'auth');
            console.log('updateCustomerCar');
        } catch (error) {
            this.$emit("setisloading",false);
            serviceMain.showErrorAlert(this,error)
        }
    },
  },
  computed:{
    getBU:function(){
      return this.$store.getters['customer/getQTBU']
    },
    getQTCUSTREF:function(){
      return this.$store.getters['customer/getQTCUSTREF'];
    },
    getQTDFSTORE:function(){
      return this.$store.getters['service/getQTDFSTORE']
    },
    getQTCUSTINFO: function() {
      return this.$store.getters["customer/getQTCUSTINFO"];
    },
    qtShopItem: {
      get() {
        return this.$store.getters["customer/getQTSHOPITEM"];
      },
      set(value) {
        this.$store.dispatch("customer/setQTSHOPITEM", value);
      },
    },
    getBuColor: function() {
      let color = "";
      switch (this.qtBu) {
        case "bnb":
          color = "#006e63";
          break;
        case "at1":
          color = "#3866af";
          break;
        default:
          color = "#55246c";
      }
      return color;
    },
  },
  watch: {
      getQTDFSTORE: function() {
        this.showListCart();
      },
    },
};
</script>

<style scoped lang="scss">
.input-History{
  border: 1px gray solid;
  padding: 0px 15px;
  border-radius:4px ;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.track {
     position: relative;
     background-color: none;
     height: 1px;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     margin-bottom: 50px;
     margin-top: 40px;
     //margin-left:-40px;
 }

 .line {
     position: relative;
     background-color: #CCC;
     height: 1px;
     width: 40%;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
 }

 .track .step {
     -webkit-box-flex: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     width: 14%;
     margin-top: -18px;
     text-align: center;
     position: relative
 }

 .track .step.active .icon {
    background: #5cb85c;
    color: #fff;
    padding-top: 5px;
    font-size: 18px;
 }
 
 .track .step.cancel .icon {
     background: #55246c;
     color: #fff
 }

 .track .step.none .icon {
     background: none;
     color: #fff
 }

 .track .icon {
     display: inline-block;
     width: 30px;
     height: 30px;
     line-height: 30px;
     position: relative;
     border-radius: 100%;
     background: #ddd
 }

 .icon > .fa {
     font-size: 15px !important;
     margin-top: 8px;
 }

 .track .step.active .text {
     font-weight: 400;
     color: #000;
 }

 .lineca.none{
     background: none;
 }

 .track .text {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
 .track .textr {
     font-size: 15px !important;
     display: block;
     margin: 7px -20px 0 -20px;
 }
.bt {
    background-color: #55246c;
    font-weight: bold;
    color: white;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
.print-Div{
  width: 1000px;
}
.card-white {
    position: relative;
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 15px;
    margin: 0px 0px 10px 0px;
  }
</style>
