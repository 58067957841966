<template>
<section>
    <div class="row login-form" style="padding: 0px 40px ; ">
        <!--   NEW MEMBER  -->
        <div id="form-regis" class="col-md-12 col-regis">
            <h2 style="margin-top: 5px;">ค้นหาเลขที่ใบงานใน TABLET </h2>
            <div class="row mb-3">
                <div class="col-12" style="margin: 0px; background-color: white; padding:20px 20px; border-radius: 7px; height: auto;  min-height: auto;">
                    <div class="row">
                        <div class="form-group col-12" style="padding: 0px;">
                            <div class="col-12">
                                <label for="ORDER">กรุณาระบุเลขที่ใบงานใน TABLET</label> <span style="color: red;">*</span>
                            </div>
                            <div class="col-12">
                                <input v-model="orderNo" type="text" class="" style="width: 100% !important; border-radius: 4px; border: .5px solid #ccc; display: inline-block !important; " 
                                ref="ORDER" name="ORDER" :placeholder="`${qtDfStore ? '' : 'กรุณาเลือกสาขา'}`">
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <button type="button" :class="`twdorbnb btn-${getQTBU}`" style=" " @click="getOrder_AT1('')" > <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ดึงข้อมูลสินค้าจากใบงานใหม่ทั้งหมด</strong></button>
                        </div>
                        <div class="form-group col-12">
                            <button type="button" :class="`twdorbnb btn-${getQTBU}`" style=" " @click="getOrder_AT1('A')" > <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">รายการเสนอราคาเพิ่มเติม (Plussales)</strong></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-show="false" v-if="mobileForm">
                <div class="col-12 col-md-6"  style="margin: 0px; background-color: white; padding:20px 20px; border-radius: 7px; height: auto; min-height: auto;">
                    <div class="row">
                        <div class="form-group col-12" style="padding: 0px;">
                            <div class="col-12">
                                <label for="MOBILE">กรุณาระบุเบอร์โทรศัพท์</label> <span style="color: red;">*</span>
                            </div>
                            <div class="col-12">
                                <input v-model="dataRegister.MOBILE" type="text" class="" style="width: 100% !important; border-radius: 4px; border: .5px solid #ccc; display: inline-block !important; "
                                ref="MOBILE" name="MOBILE" placeholder="" maxlength="10" disabled>
                            </div>
                        </div>
                        <div class="form-group col-12">
                            <button type="button" :class="`twdorbnb btn-${getQTBU}`" style=" " @click="findUser"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ค้นหาข้อมูล</strong></button>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6" v-if="emailForm" style="margin: 0px; background-color: white; padding:20px 20px; border-radius: 7px; height: auto; min-height: auto;">
                    <div class="row" >
                        <div class="col-12 twdorbnb" v-if="loadT1C">
                            <form>
                                <div class="form-group p-0">
                                    <label for="seluser">E-mail ของลูกค้าคือ</label>
                                    <select v-model="dataEmail" name="seluser" @change="seluserchang" class="form-control selectMail">
                                        <option v-for="(e,index) in listUser" :key="index" :value="e.EMAIL">
                                            {{ e.EMAIL }}
                                        </option>
                                    </select>
                                </div>
                                <a :class="`col-12 twdorbnb btn-${getQTBU}`" @click="confirmSelectedUser()"> <strong style="font-family: 'kittithadamedium65regular';">เลือก</strong></a>
                                <button type="button" :class="`mt-1 twdorbnb btn-visible-black`" style=" " @click="clearStore"> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ยกเลิก</strong></button>
                            </form>
                        </div>
                        <div class="col-12 twdorbnb" v-else>
                            <form>
                                <div class="form-group p-0">
                                    <label for="seluser" style="font-family: 'kittithadabold75regular'; color:black; ">E-mail ของลูกค้าคือ</label>
                                    <p><span class="skeleton-box w-100" style="height: 30px; border-radius:50px;"></span></p>
                                </div>
                                <a :class="`col-12 twdorbnb btn-${getQTBU}`" :style="{backgroundColor:'#aaa'}"  > <strong style="font-family: 'kittithadamedium65regular';">เลือก</strong></a>
                                <button type="button" :class="`mt-1 twdorbnb btn-visible-black`" style=" "> <strong style="font-size: 20px !important; font-family: 'kittithadamedium65regular';">ยกเลิก</strong></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import SelectStore from '@/components/SelectStore.vue'
import EditUserInfoForm from '@/components/EditUserInfoForm.vue'
import SelectSaleSource from '@/components/FindUser/SelectSaleSource.vue'
export default {
    name: 'OrderFromAuto1',
    data: function () {
        return {
            orderNo: '',
            listUser: [],
            dataEmail: '',
            emailForm: false,
            userInfoForm: false,
            editForm: false,
            dataSelected: {
                CNAME: '',
                MOBILE: '',
                EMAIL: '',
                T1C: '',
                VIPID: '',
                SALESOURCE: this.$store.getters['auth/getUser'].QTSALESOURCE,
                EMPLOYEEBUSHORTCODE: '',
                EMPLOYEEID: '',
                ISSTAFF: '',
            },
            salesourceCondition: ['UL', 'UD', 'UC'],
            loadT1C: true,
            dataRegister: {
                MOBILE: "",
                EMAIL:"",
                CNAME:"",
                VIPID:"",
                T1C:"",
                SALESOURCE: "",
            },
            mobileForm: false,
            customerCar:'',
            orderStore:'',
        }
    },
    components: {
        EditUserInfoForm,
        SelectStore,
        SelectSaleSource
    },
    props: [
    ],
    mounted() {
        
    },
    methods: {
        async findUser() {
            try {

                // if (this.dataPhone == '') {
                //     this.$refs.MOBILE.focus()
                //     throw new Error('กรุณากรอกหมายเลขโทรศัพท์');
                // }
                this.listEmail = []
                let getAPI = await serviceAPI.call_API('get', `user/Custinfo/${this.dataRegister.MOBILE}`, {}, 'auth');
                this.listUser = getAPI.data.dbitems;
                this.dataEmail = this.listUser[0] ? this.listUser[0].EMAIL : null
                if(!this.dataEmail) {
                    await this.$emit("getshowRegister", true);
                    this.$emit("setisloading",false);
                    throw new Error('ไม่พบลูกค้าในระบบ กรุณาสมัครสมาชิกก่อนเข้าใช้งาน');
                }
                this.mobileForm = true
                this.loadT1C = false
                this.emailForm = true;
                this.seluserchang()
                
            } catch (error) {
                this.$emit("setisloading",false);
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async seluserchang() {
            try {
                let getEmail = this.dataEmail;
                this.userInfoForm = true;
                for (let e of this.listUser) {
                    if (e.EMAIL == getEmail) {
                        this.dataSelected.CNAME = e.CNAME;
                        this.dataSelected.MOBILE = e.MOBILE;
                        this.dataSelected.EMAIL = e.EMAIL;
                        this.dataSelected.T1C = e.T1C;
                        this.dataSelected.VIPID = e.VIPID;

                        break
                    }
                }
                this.getTheOneNumber()
                

            } catch (error) {
                this.$emit("setisloading",false);
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async getTheOneNumber(){
            try {
                // if(this.dataSelected.MOBILE.length != 10){
                //     return
                // }
                let data = this.dataSelected;
                let getAPI = await serviceAPI.call_API('post', 'user/findTheOne', data,'auth');
                let getData = getAPI.data.dbitems[0];
                this.loadT1C = true
                if(!getData ||getData.T1CNumber=='NOOK'){
                    // throw new Error('ไม่พบเลขเดอะวัน')
                    // this.dataSelected.T1C = getData.T1CNumber || '' ;
                    this.dataSelected.EMPLOYEEBUSHORTCODE = '';
                    this.dataSelected.EMPLOYEEID = '';
                    this.dataSelected.ISSTAFF = '';
                    await this.confirmSelectedUser()
                    return
                }
                // this.dataSelected.T1C = getData.T1CNumber || '' ;
                // this.dataSelected.CNAME = `${getData.MemberFirstNameThai} ${getData.MemberLastNameThai}`;
                this.dataSelected.EMPLOYEEBUSHORTCODE = getData.EmployeeBUShortCode || '';
                this.dataSelected.EMPLOYEEID = getData.EmployeeId || '';
                this.dataSelected.ISSTAFF = getData.EmployeeFlag || '';
                await this.confirmSelectedUser()
            } catch (error) {
                this.loadT1C = true
                this.dataSelected.EMPLOYEEBUSHORTCODE = '';
                this.dataSelected.EMPLOYEEID = '';
                this.dataSelected.ISSTAFF = '';
                await this.confirmSelectedUser()
                // serviceMain.showErrorAlert(this, error.message)
            }
        },
        async confirmEdit(para_valEdit) {
            try {
                this.dataSelected = para_valEdit;
            } catch (error) {
                serviceMain.showErrorAlert(this, error.message)
            }
        },
        async confirmSelectedUser() {
            try {
                // if (this.salesourceCondition.includes(this.getQTSUBSALESOURCE)) {
                //     document.getElementById('sel-salesource').scrollIntoView()
                //     throw new Error("กรุณาเลือก SaleSource ก่อนครับ")
                // }

                if (this.dataSelected.EMAIL == '') {
                    this.$emit("setisloading",false);
                    throw new Error("กรุณาเลือก Email ก่อนครับ")
                }
                let data = {
                    "MCID": this.$store.getters['customer/getQTMCID'],
                    "EMAIL": this.dataSelected.EMAIL,
                    "MOBILE": this.dataSelected.MOBILE,
                    "USERID": this.$store.getters['auth/getQTUSERID'],
                    "ORDERNO": this.orderNo,
                }
                // console.log(data)
                let getAPI = await serviceAPI.call_API('post', 'at1Order/getCustrefAuto1', data, 'auth');
                
                let getData = getAPI.data.dbitems[0];
                // console.log(getData);
                
                let getCUSTREF = getData.CUSTREF;
                // console.log(getCUSTREF)
                let getNITEM = getData.SUMQNT;
                let getCURSTC = getData.CURSTC.trim();
                this.$store.dispatch('customer/setQTCUSTINFO', this.dataSelected)
                this.$store.dispatch('customer/setQTCUSTREF', getCUSTREF)
                this.$store.dispatch('customer/setQTSHOPITEM', getNITEM)
                if (getCURSTC) {
                    this.$store.dispatch('service/setQTDFSTORE', getCURSTC)
                    let getAPI = await serviceAPI.call_API('post', 'service/findBU', {
                        "STCODE": getCURSTC
                    }, 'auth');
                    let getBU = getAPI.data.dbitems[0].BU;
                    this.$store.dispatch('customer/setQTBU', getBU)

                    let getQTSAVESTORE = this.$store.getters['service/getQTSAVESTORE']
                    let locationsTEMP = getQTSAVESTORE.split('|');
                    let locations = locationsTEMP.map(e => e.split(','));
                    let getQTStoreNow = getCURSTC
                    let QTStoreName = locations.filter(e => e[0] == getQTStoreNow);
                    let getStoreName = QTStoreName[0][1];
                    this.$store.dispatch('service/setQTSTORENAME', getStoreName)
                } else {
                    if(!this.qtDfStore) {
                        $('#mapModal').modal('show')
                    }
                }
                
                let getSaleSource = getData.SALESOURCE;
                if(this.salesourceCondition.includes(getSaleSource)){
                    this.$store.dispatch('auth/setQTSUBSALESOURCE', this.getQTSUBSALESOURCE)
                }else{
                    this.$store.dispatch('auth/setQTSUBSALESOURCE', getSaleSource)
                }
                    
                this.updateSaleSource()
                this.$emit("getshowListItems", true);
            } catch (error) {
                this.$emit("setisloading",false);
                serviceMain.showErrorAlert(this, error.message)
            }

        },
        async updateSaleSource(){
            try {

                if(!this.getRef){
                this.$emit("setisloading",false);
                throw new Error("กรุณาเลือกตะกร้าก่อนครับ")
                }
                let data={
                REF: this.getRef, 
                SALESOURCE:this.getQTSUBSALESOURCE
                }
                let getAPI = await serviceAPI.call_API('post',`user/updateSalesource`,data,'auth');
            
                
            } catch (error) {
                this.$emit("setisloading",false);
                serviceMain.showErrorAlert(this,error.message)
            }
        },
        clearStore(){
            this.$store.dispatch('service/clearStore')
            this.$store.dispatch('customer/clearCustomer')
        },
        async getOrder_AT1(M){
            try {
                this.$emit("setisloading",true);
                await this.clearData()
                if(!this.qtDfStore) {
                    this.$emit("setisloading",false);
                    throw new Error(`กรุณาเลือกสาขาก่อน`)
                }
                let data = {OrderNumber:this.orderNo}
                // let data = {OrderNumber:'BNA-S-22050004'}
                // let getAPI1 = await serviceAPI.call_API('post', 'at1Order/checkConfirmOrder', data,'auth');
                // let getData1 = getAPI1.data.dbitems
                // if(getData1.length != 0){
                //     this.$emit("setisloading",false);
                //     throw new Error(`เลขที่ใบงาน ${this.orderNo} สร้างใบเสนอราคาแล้ว`)
                // }
                let getAPI = await serviceAPI.call_API('post', 'at1Order/getOrder_AT1', data,'auth');
                let getData = getAPI.data.data
                // console.log(getData);
                if(getData.Message){
                    this.$emit("setisloading",false);
                    throw new Error(`ไม่พบเลขที่ใบงาน ${this.orderNo}`)
                }
                this.dataRegister = {
                    MOBILE: getData.CustomerTelephone,
                    EMAIL:`mr${getData.A1UserId}@dummy.com`,
                    CNAME:`${getData.CustomerFirstname} ${getData.CustomerLastname}`,
                    VIPID:getData.A1UserId,
                    T1C:getData.The1CardNo || "",
                    SALESOURCE: "A1",
                }
                this.orderStore = getData.StoreCode
                let CustomerCar = {CustomerCarPlate: getData.CustomerCarPlate || '', //ทะเบียนรถ
                                   CustomerCarProvince: getData.CustomerCarProvince || '', //จงัหวัด
                                   CarBrand: getData.CarBrand || '', //ยี่ห้อรถ
                                   CarModel: getData.CarModel || '', //รุ่นรถ
                                   CarYear: getData.CarYear || '', //ปีรถ
                                   CarYearSeries: getData.CarYearSeries || '', //ปีรถ
                                   Gear: getData.Gear || '', //เกียร์
                                   Chassis: getData.Chassis || '', //เลขตัวถัง
                                   Engine: getData.Engine || '', //ขนาดเครื่องยนต์
                                   Color: getData.Color || '', //สีรถ
                                   CarDistance: getData.CarDistance || '',} //เลขไมล์
                await this.$emit("getlistItems", [this.dataRegister,getData.Items,this.orderNo,CustomerCar,M]);
                if(getData.CustomerTelephone == ''){
                    await this.$emit("getshowRegister", true);
                    this.$emit("setisloading",false);
                    throw new Error(`ไม่พบเบอร์โทรศัพย์ของลูกค้า`)
                }
                let dataStCode = {store:getData.StoreCode}
                let getAPI2 = await serviceAPI.call_API('post', 'at1Order/getStCode', dataStCode,'auth');
                let getData2 = getAPI2.data.dbitems
                if(getData2.length == 0){
                    this.$emit("setisloading",false);
                    throw new Error(`ไม่พบเลขที่สาขา ${getData.StoreCode}`)
                }
                if(!getData2[0].STCODE || getData2[0].STCODE != this.qtDfStore){
                    this.$emit("setisloading",false);
                    throw new Error(`สาขาไม่ตรงกับOrder`)
                    // alert(`สาขาไม่ตรงกับOrder`)
                }
                await this.findUser()
                // let getError = getAPI.data.data.errorCode
                // if(getError=="1"){
                //   let getErrorMessage = getAPI.data.data.errorMessage
                //   throw new Error(getErrorMessage);
                // }
                // let getData = getAPI.data.data.ItemList
                // if(getData.length) throw new Error('ไม่พบOrderระบบAuto1');
            } catch (error) {
                this.$emit("setisloading",false);
                serviceMain.showErrorAlert(this,error)
            }
        },
        async getCustinfo_AT1(){
            try {
                let data = {MOBILE:this.dataRegister.MOBILE}
                let getAPI = await serviceAPI.call_API('post', 'at1Order/getCustinfo_AT1', data,'auth');
                // console.log(getAPI);
                // let getError = getAPI.data.data.errorCode
                // if(getError=="1"){
                //   let getErrorMessage = getAPI.data.data.errorMessage
                //   throw new Error(getErrorMessage);
                // }
                let getData = getAPI.data.data.ItemList
                if(getData.length) throw new Error('ไม่พบลูกค้าในระบบAuto1 กรุณาสมัครสมาชิกก่อนเข้าใช้งาน');
            } catch (error) {
                serviceMain.showErrorAlert(this,error)
            }
        },
        async clearData(){
           this.dataRegister = {
                MOBILE: "",
                EMAIL:"",
                CNAME:"",
                VIPID:"",
                T1C:"",
                SALESOURCE: "",
            },
            this.emailForm = false
            this.mobileForm = false
            this.loadT1C = true
            this.$emit("getlistItems", [this.dataRegister,[]]);
            this.$emit("getshowRegister", false);
            this.$emit("getshowListItems", false);
        }
 
    },
    computed: {
        getRef:function(){
            return this.$store.getters['customer/getQTCUSTREF']
        },
        getQTBU: function () {
            return this.$store.getters['customer/getQTBU'];
        },
        qtDfStore: function () {
            return this.$store.getters['service/getQTDFSTORE'];
        },
        getQTSALESOURCE: function () {
            return this.$store.getters['auth/getQTSALESOURCE']
        },
        getQTSUBSALESOURCE: function () {
            return this.$store.getters['auth/getQTSUBSALESOURCE']
        },
        getQTETAX: function () {
            return this.$store.getters['auth/getQTETAX']
        }
    }

}
</script>

<style scoped>
.cardWhite {
    margin: 0px;
    background-color: white;
    padding: 20px 20px;
    border-radius: 7px;
    height: auto;
    margin-bottom: 0px;
    min-height: auto;
}

.selectMail {
    border-radius: 15px !important;
    height: 35px;
    padding: 0px 15px;
    font-family: 'kittithadabold75regular';
    color: black;
    background-color: rgb(241, 241, 241);
    border-radius: 25px;
}

.form-control {
    height: 30px;
    padding: 0px 15px;
    width: 100% !important;
    border-radius: 4px;
    border: .5px solid #ccc;
    display: inline-block !important;
}

.col-regis {
    padding: 0px 20px !important;
}

.checkmark {
    top: 4px !important;
    height: 20px !important;
    width: 20px !important;
}

.checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: white;
    border-radius: 50%;
    border-style: solid;
    border-color: #999999;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.shadowDiv {
    margin: 0px;
    background-color: white;
    padding: 10px;
    border-radius: 7px;
    height: auto;
}
.shadowDiv:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
